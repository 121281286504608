<template>
  <iframe
      src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2960.684735409523!2d11.792541315742422!3d42.09280385992913!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x1328a3568c9b69a1%3A0x8c97d05d8fd62540!2sVia%20Traiana%2C%2073%2C%2000053%20Civitavecchia%20RM!5e0!3m2!1sit!2sit!4v1614889760292!5m2!1sit!2sit"
      width="100%"
      height="450"
      style="border:0;"
      allowfullscreen=""
      loading="lazy"></iframe>
</template>

<script>
export default {
name: "Maps"
}
</script>

<style scoped>

</style>