<template>
  <section class="my-5">
    <div class="container">
      <div class="row">
        <div class="col-12 text-center">
          <article>
            <h1>
              Raggiungi i tuoi obiettivi con noi
            </h1>
            <p class="description mb-5 w-75 m-auto">
              Nel 1986 il rag. Mauro Rizzitiello, appena iscritto all’Albo, apri’ lo studio medesimo insieme ai suoi tre figli: Stefano, Maurizio e Fabio.
              Nel 1995 Stefano si iscrisse all’ordine dei ragionieri di Roma, iniziando cosi’ ad affiancare il padre Mauro; il quale nel 1996, insieme ad altri colleghi, costitui’ il nascente ordine dei ragionieri di Civitavecchia.
              Nel 2008, con la legge che univa gli ordini dei ragionieri e dottori commercialisti, si venne a creare l’attuale ordine dei dottori commercialisti ed esperti contabili di Civitavecchia a cui venivano iscritti sia Mauro che Stefano Rizzitiello.
              il 20/11/2018 veniva a mancare Mauro e lo studio e’ gestito dal figlio Stefano.
            </p>
          </article>
        </div>
      </div>
      <div class="row">
        <div class="col-12 d-flex justify-content-center flex-wrap">
          <article class="image-card bg-lite-white mb-5 mb-lg-0 me-lg-3">
            <div>
              <img
                  src="@/assets/images/studio.png"
                  alt="Immagine studio"
                  class="w-100"
              />
            </div>
            <div class="text-center p-4">
              <h1 class="mb-4 c-blue">
                Lo Studio
              </h1>
              <p class="m-auto w-75 about-description c-blue ">
                Rag. Stefano Rizzitiello <br /> <br />
                <h5 class="fw-bold text-uppercase">COLLABORATORI</h5>
                Rag. Maurizio Rizzitiello <br />
                Rag. Giovanna Delfino <br />
                Rag. Maria Campana <br />
                Sig.na Desiree' Benevieri <br />
              </p>
            </div>
          </article>
          <article class="image-card bg-lite-white ms-lg-3">
            <div>
              <img
                  src="@/assets/images/studio.png"
                  alt="Immagine studio"
                  class="w-100"
              />
            </div>
            <div class="text-center p-4">
              <h1 class="mb-4 c-blue">
                La Professione
              </h1>
              <p class="m-auto w-75 about-description c-blue ">
                Iscritto all'ordine dei dottori commercialisti ed esperti contabili di Civitavecchia al N.126 sezione "A" con
                anzianità di servizio dal 07/11/1995.
                Iscritto all'Albo dei revisori contabili al N.107067 con DM iscrizione del 25/11/1999 pubblicato in Gazzetta N.100 del 17/12/1999.
                Abilitato alla tenuta della contabilità del lavoro con comunicazione all'ispettorato del lavoro del 30/06/2003.
              </p>
            </div>
          </article>
        </div>
      </div>
    </div>
  </section>
  <section>
    <Maps />
  </section>
</template>

<script>
import Maps from "@/components/Maps";
export default {
  name: "About",
  components: {Maps},
}
</script>

<style scoped>
  .image-card {
    width: 365px;
    height: 738px;
  }

  .description {
    font-family: 'Sanchez', sans-serif;
  }

  .image-card .about-description {
    font-family: 'Sanchez', sans-serif;
    line-height: 28px;
  }
</style>